import React from "react";
import logo from "./logo.svg";
import config from "./config";
import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Chuan Bin",
      short_description: "I'm a Computer Enthusiast | Senior Software Engineer",
      first_address: "Blk 291D Bukit Batok Street 24 #13-25",
      sec_address: "Singapore 653291",
      my_skills: [
        {
          icon: "icon-mobile-phone",
          skill_name: "Mobile App Development",
          level: 8
        },
        { icon: "icon-pan_tool", skill_name: "Java", level: 8 },

        { icon: "icon-pan_tool", skill_name: "JavaScript", level: 7 },
        { icon: "icon-pan_tool", skill_name: "React Native", level: 7 },
        {
          icon: "icon-cloud",
          skill_name: "Backend Development",
          level: 7
        },
        { icon: "icon-pan_tool", skill_name: "Python", level: 6 },
        {
          icon: "icon-html5",
          skill_name: "Frontend Development",
          level: 5
        },
        { icon: "icon-pan_tool", skill_name: "C++", level: 5 },
        { icon: "icon-pan_tool", skill_name: "C", level: 5 },
        { icon: "icon-pan_tool", skill_name: "DevOps", level: 4 },
        { icon: "icon-pan_tool", skill_name: "CI/CD", level: 4 },

        { icon: "icon-pan_tool", skill_name: "Web Development", level: 3 }
      ],
      testimonials: [
        {
          cite: "Si Wai Leong | Assistant Vice President, Security Solution, Physical Security Business at Certis",
          testimonial:
            "Chuan Bin is a highly self-motivated problem solver when it come to software development. He can function independently and deliver result in a high stress environments"
        },
        {
          cite: "Eric See Kian Seng 施建成 | Techie, Technology Implementation & Consultancy",
          testimonial:
            "highly committed and dependable. possesses great drive and produces work of highest quality"
        },
        {
          cite: "Daniel peck ming xuan",
          testimonial:
            "Chuan Bin was my supervisor during my internship. He has guided me and shown me the ropes of how to improve my programming skills while challenging me with various projects. Despite his many responsibilities with different concurrent projects, he has always remained calm and is able to work under stress."
        },
        {
          cite: "airy huang | Certis Solutions Architect",
          testimonial:
            "當Chuan Bin剛加入團隊時，他以年輕人的姿態，迅速讓我們這些資深的技術人員感到佩服，無論再難的技術交到他手上，都能完美完成。他是個熱衷於思考技術，並且勇於實踐的優秀工程師，在他帶領Robot技術團隊時，也展現了他成為一個技術領導人的天分，設計規劃並且帶領著團隊一起成長克服需多技術上的難題，特別的是在資源不足的情況下，他經常一肩挑起了所有的重任。另外，他有很好的人格特質，是個每人都想和他一起工作的人。"
        }
      ],
      about_me1:
        "I am a computer enthusiast who loves computer games and likes to tinker with computer systems and has a good grasp of logical coding languages such as Java, JavaScript, C, C++, C#, PHP, HTML and Python. I like to do small projects during my free time and explore different tools available on the net.",
      about_me2:
        "Success is to be defined by an individual's satisfaction in terms of achieving a personal quality of life. My term of success is the ability to sustain my family physically and financially by doing what I love as a career.",
      first_name: "",
      last_name: "",
      sender_email: "",
      email_subject: "",
      email_message: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    // console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    var name = this.state.first_name + " " + this.state.last_name;
    await fetch(config.api_url + config.send_email_extend, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name,
        email: this.state.sender_email,
        subject: this.state.email_subject,
        message: this.state.email_message
      })
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        console.log("Getting response... ");
        console.log(res);
        if (res.success) {
          alert(
            "Your email has been sent! Thank you for contacting me! I will response ASAP!"
          );
        } else {
          console.log(res.data);
          alert("Something went wrong :(");
        }
      })
      .catch(res => {
        console.log(res.data);
        alert("Something went wrong... :( probably server API error");
      });
  }

  renderSkills(items, key) {
    return (
      <div class="col-md-6 mb-4" key={key}>
        <div class="service d-flex h-100">
          <div class="service-number mr-4">
            <span class={items.icon}></span>
          </div>
          <div class="service-about">
            <div class="row justify-content-start">
              <h3>{items.skill_name}</h3>
            </div>
            <p />
            <div class="row justify-content-start">
              {(function() {
                let stars = [];
                for (let i = 0; i < items.level; i++) {
                  stars.push(<span class="icon-asterisk"></span>);
                }
                return stars;
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTestimonials(items, key) {
    return (
      <div class="slide" key={key}>
        <blockquote>
          <p>{items.testimonial}</p>
          <p>
            <cite>&mdash; {items.cite}</cite>
          </p>
        </blockquote>
      </div>
    );
  }

  render() {
    return (
      <div className="App">
        <div class="site-wrap">
          <div class="site-mobile-menu site-navbar-target">
            <div class="site-mobile-menu-header">
              <div class="site-mobile-menu-close mt-3">
                <span class="icon-close2 js-menu-toggle"></span>
              </div>
            </div>
            <div class="site-mobile-menu-body"></div>
          </div>
          <header
            class="site-navbar py-4 js-sticky-header site-navbar-target"
            role="banner"
          >
            <div class="container-fluid">
              <div class="row align-items-center justify-content-center">
                <div class="">
                  <nav
                    class="site-navigation position-relative text-right"
                    role="navigation"
                  >
                    <ul class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                      <li>
                        <a href="#home-section" class="nav-link">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="#about-section" class="nav-link">
                          About
                        </a>
                      </li>
                      <li>
                        <a href="#skills-section" class="nav-link">
                          Skills
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div class=" text-center mx-5">
                  <h1 class="m-0 site-logo">
                    {/* <a href="index.html">Me.</a> */}
                    <a href="index.html">
                      <img
                        style={{ width: 50, height: 50, resizeMode: "contain" }}
                        src="images/main.png"
                      />
                    </a>
                  </h1>
                </div>

                <div class="text-left">
                  <nav
                    class="site-navigation position-relative"
                    role="navigation"
                  >
                    <ul class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                      <li>
                        <a href="#portfolio-section" class="nav-link">
                          Portfolio
                        </a>
                      </li>
                      {this.state.testimonials.length < 1 ? null : (
                        <li>
                          <a href="#testimonials-section" class="nav-link">
                            Testimonials
                          </a>
                        </li>
                      )}
                      <li>
                        <a href="#contact-section" class="nav-link">
                          Contact
                        </a>
                      </li>
                    </ul>
                  </nav>

                  <div
                    class="d-inline-block d-lg-none"
                    style={{ position: "relative", top: 3 }}
                  >
                    <a
                      class="site-menu-toggle js-menu-toggle float-right"
                    >
                      <span class="icon-menu h3"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <section class="site-blocks-cover overlay bg-light" id="home-section">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-12 mt-lg-5 text-left align-self-center text-intro">
                  <div class="row">
                    <div class="col-lg-6">
                      <h1 class="text-black">Hi, I'm {this.state.name}</h1>
                      <p class="lead">{this.state.short_description}</p>
                      <p>
                        <a
                          href="#portfolio-section"
                          class="btn smoothscroll btn-primary"
                        >
                          Portfolio
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  src="images/face.jpeg"
                  alt="Image"
                  class="img-face"
                  // style={{ background: "white", opacity: 0.2, marginLeft: 0 }}
                  // data-aos="fade"
                />
              </div>
            </div>
          </section>

          <section class="site-section" id="about-section">
            <div class="container">
              <div class="row ">
                <div class="col-12 mb-4 position-relative">
                  <h2 class="section-title">About Me</h2>
                </div>
                <div class="col-lg-4 order-1 order-lg-2 mb-4 mb-lg-0">
                  {/* <div class="bg-light pt-5"> */}
                  <img
                    src="images/about_me.jpg"
                    alt="Image"
                    class="img-fluid"
                  />
                  {/* </div> */}
                </div>
                <div class="col-lg-4 order-2 order-lg-1">
                  <p>{this.state.about_me1}</p>
                  <p>
                    <a
                      href="/downloads/Chee_Chuan_Bin_Resume.pdf"
                      class="btn smoothscroll btn-primary"
                      download
                    >
                      Resume
                    </a>
                  </p>
                </div>
                <div class="col-lg-4 order-3 order-lg-3">
                  <p>How I define success:</p>
                  <p>{this.state.about_me2}</p>
                  <p>
                    <a
                      href="#contact-section"
                      class="btn smoothscroll btn-primary"
                    >
                      Contact Me
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="site-section" id="skills-section">
            <div class="container">
              <div class="row ">
                <div class="col-12 mb-5 position-relative">
                  <h2 class="section-title text-center mb-5">My Skills</h2>
                </div>

                {this.state.my_skills.map((items, index) => {
                  // This will render a row for each data element.
                  return this.renderSkills(items, index);
                })}
              </div>
            </div>
          </section>

          <section class="site-section block__62272" id="portfolio-section">
            <div class="container">
              <div class="row mb-5">
                <div class="col-12 position-relative">
                  <h2 class="section-title text-center mb-5">My Portfolio</h2>
                </div>
              </div>

              {/* <div id="posts" class="row no-gutter">
                <div class="item web col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_2.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_2.jpg" />
                  </a>
                </div>
                <div class="item web col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_3.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_3.jpg" />
                  </a>
                </div>

                <div class="item brand col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_4.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_4.jpg" />
                  </a>
                </div>

                <div class="item design col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_5.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_5.jpg" />
                  </a>
                </div>

                <div class="item web col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_6.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_6.jpg" />
                  </a>
                </div>

                <div class="item brand col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_2.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_2.jpg" />
                  </a>
                </div>

                <div class="item web col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_3.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_3.jpg" />
                  </a>
                </div>

                <div class="item design col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_4.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_4.jpg" />
                  </a>
                </div>

                <div class="item web col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                  <a href="images/post_6.jpg" class="item-wrap fancybox">
                    <span class="icon-search2"></span>
                    <img class="img-fluid" src="images/post_6.jpg" />
                  </a>
                </div>
              </div> */}
            </div>
          </section>

          {/* <section class="site-section" id="clients-section">
            <div class="container">
              <div class="row">
                <div class="col-12 mb-5 position-relative">
                  <h2 class="section-title text-center">Skills</h2>
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center">
                  <img src="images/logo_1.jpg" alt="Image" class="img-fluid" />
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center">
                  <img src="images/logo_2.jpg" alt="Image" class="img-fluid" />
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center">
                  <img src="images/logo_3.jpg" alt="Image" class="img-fluid" />
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center">
                  <img src="images/logo_4.jpg" alt="Image" class="img-fluid" />
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center">
                  <img src="images/logo_5.jpg" alt="Image" class="img-fluid" />
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center">
                  <img src="images/logo_6.jpg" alt="Image" class="img-fluid" />
                </div>
              </div>
            </div>
          </section> */}

          {this.state.testimonials.length < 1 ? null : (
            <section class="site-section bg-dark" id="testimonials-section">
              <div class="container">
                <div class="row">
                  <div class="col-12 mb-5 position-relative">
                    <h2 class="section-title text-center mb-5 text-white">
                      Testimonials
                    </h2>
                  </div>
                </div>
                <div class="owl-carousel slide-one-item">
                  {this.state.testimonials.map((items, index) => {
                    return this.renderTestimonials(items, index);
                  })}
                </div>
              </div>
            </section>
          )}

          <section class="site-section" id="contact-section">
            <div class="container">
              <div class="row">
                <div class="col-12 mb-5 position-relative">
                  <h2 class="section-title text-center mb-5">Contact Form</h2>
                </div>
              </div>
              <form onSubmit={this.handleSubmit} class="form">
                <div class="row mb-4">
                  <div class="form-group col-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      name="first_name"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group col-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last name"
                      name="last_name"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="form-group col-12">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email address"
                      name="sender_email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="form-group col-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Subject of the message"
                      name="email_subject"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="form-group col-12">
                    <textarea
                      name="email_message"
                      id=""
                      cols="30"
                      rows="10"
                      class="form-control"
                      onChange={this.handleChange}
                      placeholder="Type your message here.."
                    ></textarea>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <input
                      type="submit"
                      class="btn btn-primary"
                      value="Send Message"
                    />
                  </div>
                </div>
              </form>
            </div>
          </section>
          <footer class="site-section bg-light footer">
            <div class="container">
              <div class="row mb-5">
                {/* <div class="col-md-3" style={{ backgroundColor: "red" }}>
                  <h3 class="footer-title">Me Address</h3>
                  <p>
                    <span class="d-inline-block d-md-block">
                      {this.state.first_address}
                    </span>{" "}
                    {this.state.sec_address}
                  </p>
                </div>
                <div
                  class="col-md-5 mx-auto"
                  style={{ backgroundColor: "yellow" }}
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <h3 class="footer-title">Services</h3>
                      <ul class="list-unstyled">
                        <li>
                          <a>Web App</a>
                        </li>
                        <li>
                          <a>Graphic</a>
                        </li>
                        <li>
                          <a>Branding</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-4">
                      <h3 class="footer-title">Resources</h3>
                      <ul class="list-unstyled">
                        <li>
                          <a>Web App</a>
                        </li>
                        <li>
                          <a>Graphic</a>
                        </li>
                        <li>
                          <a>Branding</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-4">
                      <h3 class="footer-title">Links</h3>
                      <ul class="list-unstyled">
                        <li>
                          <a>Web App</a>
                        </li>
                        <li>
                          <a>Graphic</a>
                        </li>
                        <li>
                          <a>Branding</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div class="col">
                  <h3 class="footer-title">Follow Me</h3>
                  {/* <a class="social-circle">
                    <span class="icon-twitter"></span>
                  </a>
                  <a class="social-circle">
                    <span class="icon-facebook"></span>
                  </a>
                  <a  class="social-circle">
                    <span class="icon-instagram"></span>
                  </a>
                  <a  class="social-circle">
                    <span class="icon-dribbble"></span>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/chuanbin-chee-813a1019b"
                    class="social-circle"
                  >
                    <span class="icon-linkedin"></span>
                  </a> */}

                  <ul class="list-unstyled">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/chuanbin-chee-813a1019b"
                        target="_blank"
                      >
                        <span class="icon-linkedin-square"></span>
                        Linkedin
                      </a>
                    </li>
                  </ul>
                  <ul class="list-unstyled">
                    <li>
                      <a
                        href="https://www.facebook.com/chee.chuanbin"
                        target="_blank"
                      >
                        <span class="icon-facebook-square"></span>
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <p>
                    Copyright &copy; All rights reserved | This template is made
                    with{" "}
                    <i class="icon-heart text-danger" aria-hidden="true"></i> by{" "}
                    <a href="https://colorlib.com" target="_blank">
                      Colorlib
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default App;
